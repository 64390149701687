import { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ShoppingCart, Book, Users, Star, ChevronRight, Clock, AlertCircle, X, Monitor, Users as UsersIcon, Award, Globe, Zap, Palette, Facebook, Twitter, Instagram, Linkedin, Play, ChevronLeft, User, Mail, Phone, MapPin, Download, MessageCircle, Brush, Menu } from 'lucide-react'

type Course = {
  title: string;
  students: number;
  rating: number;
  image: string;
  description: string;
  price: string;
};

function App() {
  const [email, setEmail] = useState('');
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds
  const [showPromo, setShowPromo] = useState(true);
  const [showPdfAlert, setShowPdfAlert] = useState(true)
  const [activeGalleryItem, setActiveGalleryItem] = useState(0)
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactMessage, setContactMessage] = useState('')
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState(0)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const coursesRef = useRef<HTMLElement>(null)
  const galleryRef = useRef<HTMLElement>(null)
  const aboutRef = useRef<HTMLElement>(null)
  const contactRef = useRef<HTMLElement>(null)
  const tutorsRef = useRef<HTMLElement>(null)
  const studentsRef = useRef<HTMLElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  
  const scrollToSection = (ref: React.RefObject<HTMLElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleDownloadPdf = () => {
    const pdfUrl = '/archivo.pdf'; // Ruta relativa al archivo en la carpeta public
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'archivo.pdf'; // Nombre con el que se descargará el archivo
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    console.log('Descargando PDF...');
    setShowPdfAlert(false);
  }

  const courses = [
    { 
      title: 'Bidimensional y tridimensional basico', 
      students: 1234, 
      rating: 4.8,
      image: '/bidi.jpeg?height=400&width=600&text=Pintura+al+Óleo',
      description: 'Corte, perfilado y pulido, manejo de maquinas caseras tipo A, incluye materiales y los planos para la fabricacion de maquinas de corte.',
      price: '5000RD$'
    },
    { 
      title: 'Relieve y alto relieve', 
      students: 987, 
      rating: 4.7,
      image: '/galeria/4.jpeg?height=400&width=600&text=Escultura+Moderna',
      description: 'Corte, separacion de piezas, perfilado y pulido, manejo de maquinas caseras tipo B, incluye materiales y los planos para la fabricacion de maquinas de corte.',
      price: '5000RD$'
    },
    { 
      title: 'Tridimensional avanzado', 
      students: 2345, 
      rating: 4.9,
      image: '/galeria/3.jpeg?height=400&width=600&text=Arte+Digital',
      description: 'Corte de material, moldes, perfilados y pulidos, uso de maquina tipo B, incluye materiales.',
      price: '5000RD$'
    },
    { 
      title: 'Aerografía y Colores', 
      students: 1567, 
      rating: 4.6,
      image: '/galeria/8.jpeg?height=400&width=600&text=Fotografía+Artística',
      description: 'Mezcla de colores, pintura automotriz, sombreado, manejo del aerografo. Aerografo categoria 2, incluye materiales.',
      price: '5000RD$'
    },
    { 
      title: 'Texturas: Fibras, resvestimiento, etc..', 
      students: 1890, 
      rating: 4.5,
      image: '/galeria/10.jpeg?height=400&width=1000&text=Dibujo+y+Bocetos',
      description: 'Uso y manejo de materiales industriales, fibras, yeso, resinas, polimeros. Incluye materiales.',
      price: '7000RD$'
    },
    { 
      title: 'Curso Completo', 
      students: 2100, 
      rating: 4.8,
      image: '/galeria/20.jpeg?height=400&width=600&text=Historia+del+Arte',
      description: 'Todos los modulos y todas las tecnicas en un solo curso. Este curso teórico y practico incluye 40 horas de lecciones, análisis de obras y materiales incluidos.',
      price: '20.000RD$'
    }
  ]

  const features = [
    { icon: Monitor, title: 'Cursos Online y Presenciales' },
    { icon: UsersIcon, title: 'Excelentes Profesores' },
    { icon: Award, title: 'Certificaciones Reconocidas' },
    { icon: Globe, title: 'Acceso 24/7' },
    { icon: Zap, title: 'Aprendizaje Interactivo' },
  ]

  const tutors = [
    {
      name: 'Miguel Diaz',
      role: 'Profesor de Artes Plasticas',
      image: '/miguel.jpeg?height=200&width=200&text=María'
    }
  ]

  const galleryItems = [
    { type: 'image', src: '/galeria/1.jpeg?height=600&width=800&text=Obra+de+Arte+1', alt: 'Obra de Arte 1' },
    { type: 'image', src: '/galeria/2.jpeg?height=600&width=800&text=Obra+de+Arte+2', alt: 'Obra de Arte 2' },
    { type: 'image', src: '/galeria/3.jpeg?height=600&width=800&text=Obra+de+Arte+3', alt: 'Obra de Arte 3' },
    { type: 'image', src: '/galeria/4.jpeg?height=600&width=800&text=Obra+de+Arte+4', alt: 'Obra de Arte 4' },
    { type: 'image', src: '/galeria/5.jpeg?height=600&width=800&text=Obra+de+Arte+5', alt: 'Obra de Arte 5' },
    { type: 'image', src: '/galeria/6.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 6' },
    { type: 'image', src: '/galeria/7.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 7' },
    { type: 'image', src: '/galeria/8.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 8' },
    { type: 'image', src: '/galeria/9.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 9' },
    { type: 'image', src: '/galeria/10.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 10' },
    { type: 'image', src: '/galeria/11.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 11' },
    { type: 'image', src: '/galeria/12.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 12' },
    { type: 'image', src: '/galeria/13.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 13' },
    { type: 'image', src: '/galeria/14.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 14' },
    { type: 'image', src: '/galeria/15.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 15' },
    { type: 'image', src: '/galeria/16.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 16' },
    { type: 'image', src: '/galeria/17.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 17' },
    { type: 'image', src: '/galeria/18.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 18' },
    { type: 'image', src: '/galeria/19.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 19' },
    { type: 'image', src: '/galeria/20.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 20' },
    { type: 'image', src: '/galeria/21.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 21' },
    { type: 'image', src: '/galeria/22.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 22' },
    { type: 'image', src: '/galeria/23.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 23' },
    { type: 'image', src: '/galeria/24.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 24' },
    { type: 'image', src: '/galeria/25.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 25' },
    { type: 'image', src: '/galeria/26.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 26' },
    { type: 'image', src: '/galeria/27.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 27' },
    { type: 'image', src: '/galeria/28.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 28' },
    { type: 'image', src: '/galeria/29.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 29' },
    { type: 'image', src: '/galeria/30.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 30' },
    { type: 'image', src: '/galeria/31.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 31' },
    { type: 'image', src: '/galeria/32.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 32' },
    { type: 'image', src: '/galeria/33.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 33' },
    { type: 'image', src: '/galeria/34.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 34' },
    { type: 'image', src: '/galeria/35.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 35' },
    { type: 'image', src: '/galeria/36.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 36' },
    { type: 'image', src: '/galeria/48.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 48' },
    { type: 'image', src: '/galeria/49.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 49' },
    { type: 'image', src: '/galeria/50.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 50' },
    { type: 'image', src: '/galeria/51.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 51' },
    { type: 'image', src: '/galeria/52.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 52' },
    { type: 'image', src: '/galeria/53.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 53' },
    { type: 'image', src: '/galeria/54.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 54' },
    { type: 'image', src: '/galeria/55.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 55' },
    { type: 'image', src: '/galeria/56.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 56' },
    { type: 'image', src: '/galeria/57.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 57' },
    { type: 'image', src: '/galeria/58.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 58' },
    { type: 'image', src: '/galeria/59.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 59' },
    { type: 'image', src: '/galeria/60.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 60' },
    { type: 'image', src: '/galeria/61.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 61' },
    { type: 'image', src: '/galeria/62.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 62' },
    { type: 'image', src: '/galeria/63.jpeg?height=600&width=800&text=Obra+de+Arte+6', alt: 'Obra de Arte 63' },

    { type: 'video', src: '/galeria/37.mp4', alt: 'Obra de Arte 37' },
    { type: 'video', src: '/galeria/38.mp4', alt: 'Obra de Arte 38' },
    { type: 'video', src: '/galeria/39.mp4', alt: 'Obra de Arte 39' },
    { type: 'video', src: '/galeria/40.mp4', alt: 'Obra de Arte 40' },
    { type: 'video', src: '/galeria/41.mp4', alt: 'Obra de Arte 41' },
    { type: 'video', src: '/galeria/42.mp4', alt: 'Obra de Arte 42' },
    { type: 'video', src: '/galeria/43.mp4', alt: 'Obra de Arte 43' },
    { type: 'video', src: '/galeria/44.mp4', alt: 'Obra de Arte 44' },
    { type: 'video', src: '/galeria/45.mp4', alt: 'Obra de Arte 45' },
    { type: 'video', src: '/galeria/46.mp4', alt: 'Obra de Arte 46' },
    { type: 'video', src: '/galeria/47.mp4', alt: 'Obra de Arte 47' },

  ]

  const studentTestimonials = [
    { name: 'Ana Martínez', course: 'Bidimensional y tridimensional basico', rating: 5, comment: 'Una experiencia transformadora. Aprendí técnicas que nunca imaginé.', image: '/estudiantes/6.jpg?height=100&width=100&text=Ana' },
    { name: 'Pedro Sánchez', course: 'Relieve y alto relieve', rating: 4, comment: 'Los instructores son verdaderos artistas. Muy inspirador.', image: '/estudiantes/1.jpg?height=100&width=100&text=Pedro' },
    { name: 'Laura Gómez', course: 'Tridimensional avanzado', rating: 5, comment: 'Ahora puedo crear arte digital profesional. ¡Increíble curso!', image: '/estudiantes/7.jpg?height=100&width=100&text=Laura' },
    { name: 'Miguel Ángel', course: 'Aerografía y Colores', rating: 5, comment: 'Cambió mi perspectiva sobre la fotografía. Altamente recomendado.', image: '/estudiantes/2.jpg?height=100&width=100&text=Miguel' },
    { name: 'Carmen Ruiz', course: 'Texturas: Fibras, resvestimiento, etc..', rating: 4, comment: 'Excelente para principiantes. Mejoré mucho en poco tiempo.', image: '/estudiantes/8.jpg?height=100&width=100&text=Carmen' },
    { name: 'Javier López', course: 'Curso Completo', rating: 5, comment: 'Fascinante viaje a través de la historia del arte. Muy enriquecedor.', image: '/estudiantes/3.jpg?height=100&width=100&text=Javier' },
    { name: 'Isabel Torres', course: 'Bidimensional y tridimensional basico', rating: 4, comment: 'Los materiales proporcionados son de primera calidad. Gran valor.', image: '/estudiantes/9.jpg?height=100&width=100&text=Isabel' },
    { name: 'Roberto Díaz', course: 'Relieve y alto relieve', rating: 5, comment: 'Desafiante pero gratificante. He descubierto una nueva pasión.', image: '/estudiantes/4.jpg?height=100&width=100&text=Roberto' },
    { name: 'Elena Vargas', course: 'Tridimensional avanzado', rating: 4, comment: 'El soporte técnico es excelente. Siempre hay alguien para ayudar.', image: '/estudiantes/10.jpg?height=100&width=100&text=Elena' },
    { name: 'Francisco Mora', course: 'Aerografía y Colores', rating: 5, comment: 'Las salidas aerográficas son lo mejor. Aprendizaje práctico invaluable.', image: '/estudiantes/5.jpg?height=100&width=100&text=Francisco' },
  ]

  const nextGalleryItem = () => {
    setActiveGalleryItem((prev) => (prev + 1) % galleryItems.length)
  }

  const prevGalleryItem = () => {
    setActiveGalleryItem((prev) => (prev - 1 + galleryItems.length) % galleryItems.length)
  }

  const nextTestimonial = () => {
    setActiveTestimonialIndex((prev) => (prev + 1) % studentTestimonials.length)
  }

  const prevTestimonial = () => {
    setActiveTestimonialIndex((prev) => (prev - 1 + studentTestimonials.length) % studentTestimonials.length)
  }

  const handleContactSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    // Simulación de envío de correo
    console.log('Enviando correo a: academia.artesimonbolivar@gmail.com')
    console.log('Datos del formulario:', { contactName, contactEmail, contactMessage })
    
    // Aquí iría la lógica real para enviar el correo
    // Por ejemplo, usando una API o un servicio de backend

    // Resetear los campos después del envío
    setContactName('')
    setContactEmail('')
    setContactMessage('')

    // Mostrar un mensaje de confirmación al usuario
    alert('Mensaje enviado con éxito. Gracias por contactarnos.')
  }

  const handleWhatsAppClick = () => {
    // Número de teléfono de WhatsApp (reemplazar con el número real)
    const phoneNumber = '+18095191093'
    // Mensaje predeterminado (opcional)
    const message = 'Hola, me gustaría obtener más información sobre los cursos.'
    // Crear el enlace de WhatsApp
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`
    // Abrir el enlace en una nueva pestaña
    window.open(whatsappUrl, '_blank')
  }

  return (
    <div className="min-h-screen bg-blue-50">
      {showPromo && (
        <div className="bg-green-500 text-white py-2 px-4 relative">
          <div className="container mx-auto flex justify-between items-center">
            <div className="flex items-center">
              <AlertCircle className="mr-2" />
              <span className="font-semibold">Promoción del 20% en el curso</span>
            </div>
            <div className="flex items-center">
              <Clock className="mr-2" />
              <span className="font-mono">{formatTime(timeLeft)}</span>
            </div>
            <motion.a
              href="#"
              className="bg-white text-green-500 px-4 py-1 rounded-full text-sm font-semibold hover:bg-green-100 transition-colors"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              ¡Aprovecha ahora!
            </motion.a>
          </div>
          <button
            onClick={() => setShowPromo(false)}
            className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white hover:text-green-200 transition-colors"
            aria-label="Cerrar promoción"
          >
            <X size={20} />
          </button>
        </div>
      )}

<nav className="bg-yellow-400 text-blue-800 p-4 shadow-md sticky top-0 z-50">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">Simón Bolívar Arte Academy</h1>
          <div className="hidden md:flex items-center space-x-4">
            <button onClick={() => scrollToSection(coursesRef)} className="hover:text-blue-600 transition-colors">Cursos</button>
            <button onClick={() => scrollToSection(tutorsRef)} className="hover:text-blue-600 transition-colors">Tutores</button>
            <button onClick={() => scrollToSection(galleryRef)} className="hover:text-blue-600 transition-colors">Galería</button>
            <button onClick={() => scrollToSection(aboutRef)} className="hover:text-blue-600 transition-colors">Nosotros</button>
            <button onClick={() => scrollToSection(contactRef)} className="hover:text-blue-600 transition-colors">Contacto</button>
            <button onClick={() => scrollToSection(studentsRef)} className="hover:text-blue-600 transition-colors">Estudiantes</button>
            <a href="#" className="hover:text-blue-600 transition-colors">
              <User size={24} />
              <span className="sr-only">Perfil de usuario</span>
            </a>
          </div>
          <button
            className="md:hidden text-blue-800 hover:text-blue-600 transition-colors"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Abrir menú"
          >
            <Menu size={24} />
          </button>
        </div>
      </nav>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="md:hidden bg-yellow-400 absolute top-full left-0 right-0 z-40 shadow-md"
          >
            <div className="container mx-auto py-4 px-4 flex flex-col space-y-4">
              <button onClick={() => scrollToSection(coursesRef)} className="text-blue-800 hover:text-blue-600 transition-colors">Cursos</button>
              <button onClick={() => scrollToSection(tutorsRef)} className="text-blue-800 hover:text-blue-600 transition-colors">Tutores</button>
              <button onClick={() => scrollToSection(galleryRef)} className="text-blue-800 hover:text-blue-600 transition-colors">Galería</button>
              <button onClick={() => scrollToSection(aboutRef)} className="text-blue-800 hover:text-blue-600 transition-colors">Nosotros</button>
              <button onClick={() => scrollToSection(contactRef)} className="text-blue-800 hover:text-blue-600 transition-colors">Contacto</button>
              <button onClick={() => scrollToSection(studentsRef)} className="text-blue-800 hover:text-blue-600 transition-colors">Estudiantes</button>
              <a href="#" className="text-blue-800 hover:text-blue-600 transition-colors flex items-center">
                <User size={24} className="mr-2" />
                <span>Perfil de usuario</span>
              </a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {showPdfAlert && (
        <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
          <div className="container mx-auto flex justify-between items-center">
            <p className="font-bold">¡Nuevo catálogo de cursos disponible!</p>
            <div className="flex items-center">
              <button
                onClick={handleDownloadPdf}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center mr-4"
              >
                <Download size={20} className="mr-2" />
                <span>Descargar PDF</span>
              </button>
              <button
                onClick={() => setShowPdfAlert(false)}
                className="text-blue-700 hover:text-blue-900"
                aria-label="Cerrar alerta"
              >
                <X size={20} />
              </button>
            </div>
          </div>
        </div>
      )}

      <section className="relative bg-blue-600 overflow-hidden">
        <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center justify-between relative z-10">
          <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0">
            <motion.h2
              className="text-4xl md:text-6xl font-bold text-white mb-4"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Descubre el Artista en Ti
            </motion.h2>
            <p className="text-xl text-blue-100 mb-8">Aprende de los mejores maestros del arte en nuestra academia en línea</p>
            <motion.button
              className="bg-yellow-400 text-blue-800 px-8 py-3 rounded-full text-lg font-semibold hover:bg-yellow-300 transition-colors flex items-center mx-auto md:mx-0"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => scrollToSection(coursesRef)}
            >
              <ShoppingCart className="mr-2" />
              Explorar Cursos
            </motion.button>
          </div>
        </div>
        <div className="absolute inset-0 flex justify-end items-center overflow-hidden">
          <div className="relative w-full md:w-1/2 h-full">
            <motion.img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/bolivar_maduro_feature2-removebg-preview%20(1)-jbm5LHaB7UCpdU4HSfgkRYiiWVqjTY.png"
              alt="Estatua de Simón Bolívar"
              className="w-full h-full object-contain object-bottom"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />
            <div className="absolute bottom-0 left-0 right-0 h-[10%] bg-gradient-to-t from-blue-600 to-transparent"></div>
          </div>
        </div>
      </section>

      <main className="container mx-auto mt-16 px-4 flex-grow">
        <section className="mb-16">
          <h3 className="text-3xl font-bold text-blue-800 mb-8 text-center">Características de Nuestros Cursos</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="flex flex-col items-center text-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <feature.icon size={48} className="text-blue-600 mb-4" />
                <h4 className="text-lg font-semibold text-blue-800">{feature.title}</h4>
              </motion.div>
            ))}
          </div>
        </section>

        <section ref={coursesRef} className="mb-16">
          <h3 className="text-3xl font-bold text-blue-800 mb-8 text-center">Cursos Destacados</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {courses.map((course, index) => (
              <motion.div 
                key={index} 
                className="bg-white rounded-lg shadow-lg overflow-hidden"
                whileHover={{ y: -5, boxShadow: '0 10px 20px rgba(0,0,0,0.1)' }}
                onClick={() => setSelectedCourse(course)}
              >
                <img 
                  src={course.image}
                  alt={`Imagen del curso ${course.title}`}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h4 className="text-xl font-semibold text-blue-600 mb-2">{course.title}</h4>
                  <div className="flex items-center text-blue-500 mb-2">
                    <Users className="w-4 h-4 mr-1" />
                    <span>{course.students} estudiantes</span>
                  </div>
                  <div className="flex items-center text-yellow-500 mb-4">
                    <Star className="w-4 h-4 mr-1" />
                    <span>{course.rating}</span>
                  </div>
                  <div className="flex items-center text-gray-600 mb-4">
                    <Clock className="w-4 h-4 mr-1" />
                    <span>8 horas de contenido</span>
                  </div>
                  <motion.button 
                    className="w-full bg-blue-600 text-white py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors"
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Ver detalles
                  </motion.button>
                </div>
              </motion.div>
            ))}
          </div>
        </section>

        <AnimatePresence>
          {selectedCourse && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
              onClick={() => setSelectedCourse(null)}
            >
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -50, opacity: 0 }}
                className="bg-white rounded-lg shadow-xl max-w-2xl w-full overflow-hidden"
                onClick={(e) => e.stopPropagation()}
              >
                <img 
                  src={selectedCourse.image}
                  alt={`Imagen del curso ${selectedCourse.title}`}
                  className="w-full h-64 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-2xl font-bold text-blue-800 mb-4">{selectedCourse.title}</h3>
                  <p className="text-gray-600 mb-4">{selectedCourse.description}</p>
                  <div className="flex justify-between items-center mb-6">
                    <span className="text-2xl font-bold text-blue-600">{selectedCourse.price}</span>
                    <div className="flex items-center">
                      <Star className="w-5 h-5 text-yellow-500 mr-1" />
                      <span>{selectedCourse.rating} ({selectedCourse.students} estudiantes)</span>
                    </div>
                  </div>
                  <motion.button 
                    className="w-full bg-blue-600 text-white py-3 rounded-full font-semibold hover:bg-blue-700 transition-colors"
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Inscribirse
                  </motion.button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <section ref={tutorsRef} className="mb-16">
          <h3 className="text-3xl font-bold text-blue-800 mb-8 text-center">Nuestros Tutores</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {tutors.map((tutor, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-lg shadow-lg p-6 flex items-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <img
                  src={tutor.image}
                  alt={`Foto de ${tutor.name}`}
                  className="w-24 h-24 rounded-full object-cover mr-6"
                />
                <div>
                  <h4 className="text-xl font-semibold text-blue-600 mb-2">{tutor.name}</h4>
                  <p className="text-gray-600">{tutor.role}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </section>

        <section ref={aboutRef} className="mb-16 bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2">
              <img 
                src="/belgica.jpeg?height=400&width=600&text=Nuestra+Academia"
                alt="Imagen de nuestra academia de arte"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="md:w-1/2 p-8">
              <h3 className="text-3xl font-bold text-blue-800 mb-4">Nosotros</h3>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <p className="text-gray-600 mb-4">
                  En la Academia de Arte Simón Bolívar, no solo enseñamos técnicas artísticas; inspiramos a nuestros estudiantes a descubrir su voz creativa única. Fundada con la visión de hacer el arte accesible a todos, nuestra academia se ha convertido en un faro de excelencia artística en América Latina.
                </p>
                <p className="text-gray-600 mb-4">
                  Nuestro equipo de instructores de clase mundial, todos artistas reconocidos en sus campos, guía a los estudiantes a través de un viaje de descubrimiento artístico. Ya sea que sueñes con exponer en galerías de renombre o simplemente desees explorar tu creatividad, tenemos el curso perfecto para ti.
                </p>
                <p className="text-gray-600 mb-6">
                  Con una combinación única de tradición artística y tecnología de vanguardia, ofrecemos una experiencia de aprendizaje incomparable. Nuestros cursos, tanto en línea como presenciales, están diseñados para adaptarse a tu estilo de vida y aspiraciones.
                </p>
                <div className="flex items-center text-blue-600 font-semibold">
                  <Palette className="w-5 h-5 mr-2" />
                  <span>Únete a nosotros y pinta tu futuro con los colores de la excelencia artística.</span>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section ref={galleryRef} className="mb-16">
          <h3 className="text-3xl font-bold text-blue-800 mb-8 text-center">Nuestra Galería</h3>
          <div className="relative">
            <div className="aspect-w-16 aspect-h-9 mb-4">
              {galleryItems[activeGalleryItem].type === 'image' ? (
                <img
                  src={galleryItems[activeGalleryItem].src}
                  alt={galleryItems[activeGalleryItem].alt}
                  className="w-full h-full object-cover rounded-lg"
                />
              ) : (
                <video
                  ref={videoRef}
                  src={galleryItems[activeGalleryItem].src}
                  className="w-full h-full object-cover rounded-lg"
                  controls
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <track kind="captions" />
                  Tu navegador no soporta el elemento de video.
                </video>
              )}
            </div>
            <button
              onClick={prevGalleryItem}
              className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full text-blue-800 hover:bg-opacity-75 transition-all"
              aria-label="Elemento anterior de la galería"
            >
              <ChevronLeft size={24} />
            </button>
            <button
              onClick={nextGalleryItem}
              className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full text-blue-800 hover:bg-opacity-75 transition-all"
              aria-label="Siguiente elemento de la galería"
            >
              <ChevronRight size={24} />
            </button>
          </div>
          <div className="flex space-x-2 mt-4 overflow-x-auto pb-2">
            {galleryItems.map((item, index) => (
              <button
                key={index}
                onClick={() => setActiveGalleryItem(index)}
                className={`flex-shrink-0 w-20 h-20 rounded-lg overflow-hidden ${
                  index === activeGalleryItem ? 'ring-2 ring-blue-500' : ''
                }`}
              >
                {item.type === 'image' ? (
                  <img
                    src={item.src}
                    alt={item.alt}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                    <Play size={24} className="text-blue-500" />
                  </div>
                )}
              </button>
            ))}
          </div>
        </section>

        <section ref={studentsRef} className="mb-16">
          <h3 className="text-3xl font-bold text-blue-800 mb-8 text-center">Nuestros Estudiantes</h3>
          <div className="relative">
            <div className="overflow-hidden">
              <motion.div
                className="flex transition-transform duration-300 ease-in-out"
                style={{ transform: `translateX(-${activeTestimonialIndex * 100}%)` }}
              >
                {studentTestimonials.map((student, index) => (
                  <div key={index} className="w-full flex-shrink-0">
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden p-6">
                      <div className="flex items-center mb-4">
                        <img
                          src={student.image}
                          alt={`Foto de ${student.name}`}
                          className="w-12 h-12 rounded-full object-cover mr-4"
                        />
                        <div>
                          <h4 className="text-lg font-semibold text-blue-600">{student.name}</h4>
                          <p className="text-sm text-gray-600">{student.course}</p>
                        </div>
                      </div>
                      <p className="text-gray-700 mb-4">"{student.comment}"</p>
                      <div className="flex items-center">
                        {[...Array(5)].map((_, i) => (
                          <Star
                            key={i}
                            className={`w-5 h-5 ${
                              i < student.rating ? 'text-yellow-400' : 'text-gray-300'
                            }`}
                            fill="currentColor"
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </motion.div>
            </div>
            <button
              onClick={prevTestimonial}
              className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full text-blue-800 hover:bg-opacity-75 transition-all"
              aria-label="Testimonio anterior"
            >
              <ChevronLeft size={24} />
            </button>
            <button
              onClick={nextTestimonial}
              className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full text-blue-800 hover:bg-opacity-75 transition-all"
              aria-label="Siguiente testimonio"
            >
              <ChevronRight size={24} />
            </button>
          </div>
        </section>

        <section ref={contactRef} className="mb-16 bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-8">
            <h3 className="text-3xl font-bold text-blue-800 mb-8 text-center">Contáctanos</h3>
            <div className="flex flex-col md:flex-row gap-8">
              <div className="md:w-1/2">
                <form onSubmit={handleContactSubmit} className="space-y-4">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Nombre</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={contactName}
                      onChange={(e) => setContactName(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Correo Electrónico</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={contactEmail}
                      onChange={(e) => setContactEmail(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Mensaje</label>
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      value={contactMessage}
                      onChange={(e) => setContactMessage(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    ></textarea>
                  </div>
                  <motion.button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded-md font-semibold hover:bg-blue-700 transition-colors"
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Enviar Mensaje
                  </motion.button>
                </form>
              </div>
              <div className="md:w-1/2">
                <div className="space-y-4">
                  <div className="flex items-center">
                    <Mail className="w-6 h-6 text-blue-600 mr-2" />
                    <span>academia.artesimonbolivar@gmail.com</span>
                  </div>
                  <div className="flex items-center">
                    <Phone className="w-6 h-6 text-blue-600 mr-2" />
                    <span>+1 829 392 5368</span>
                    <span>+1 809 519 1093</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="w-6 h-6 text-blue-600 mr-2" />
                    <span>Los Restauradores, calle Manganagua, Edificio Belgica XIX, Republica Dominicana</span>
                  </div>
                </div>
                <div className="mt-8">
                  <h4 className="text-xl font-semibold text-blue-800 mb-4">Horario de Atención</h4>
                  <p className="mb-2">Lunes a Viernes: 9:00 AM - 6:00 PM</p>
                  <p>Sábados: 10:00 AM - 2:00 PM</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue-100 p-8 rounded-lg mb-16">
          <h3 className="text-3xl font-bold text-blue-800 mb-4 text-center">Únete a Nuestra Comunidad Artística</h3>
          <p className="text-center text-blue-600 mb-6">Recibe noticias sobre nuevos cursos y consejos de arte</p>
          <form onSubmit={(e) => e.preventDefault()} className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
            <input
              type="email"
              placeholder="Tu correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="px-4 py-2 rounded-full w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <motion.button
              type="submit"
              className="bg-blue-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Suscribirse
            </motion.button>
          </form>
        </section>
      </main>

      <footer className="bg-black text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h5 className="text-xl font-bold mb-4">Simón Bolívar Arte Academy</h5>
              <p className="mb-4">Inspirando artistas desde 2023</p>
              <div className="flex space-x-4">
                <a href="#" className="text-white hover:text-blue-300 transition-colors">
                  <Facebook size={24} />
                </a>
                <a href="#" className="text-white hover:text-blue-300 transition-colors">
                  <Twitter size={24} />
                </a>
                <a href="#" className="text-white hover:text-blue-300 transition-colors">
                  <Instagram size={24} />
                </a>
                <a href="#" className="text-white hover:text-blue-300 transition-colors">
                  <Linkedin size={24} />
                </a>
              </div>
            </div>
            <div>
              <h5 className="text-xl font-bold mb-4">Enlaces Rápidos</h5>
              <ul className="space-y-2">
                <li><button onClick={() => scrollToSection(coursesRef)} className="hover:text-blue-300 transition-colors flex items-center"><ChevronRight className="w-4 h-4 mr-1" /> Cursos</button></li>
                <li><button onClick={() => scrollToSection(tutorsRef)} className="hover:text-blue-300 transition-colors flex items-center"><ChevronRight className="w-4 h-4 mr-1" /> Tutores</button></li>
                <li><button onClick={() => scrollToSection(galleryRef)} className="hover:text-blue-300 transition-colors flex items-center"><ChevronRight className="w-4 h-4 mr-1" /> Galería</button></li>
                <li><button onClick={() => scrollToSection(aboutRef)} className="hover:text-blue-300 transition-colors flex items-center"><ChevronRight className="w-4 h-4 mr-1" /> Nosotros</button></li>
                <li><button onClick={() => scrollToSection(contactRef)} className="hover:text-blue-300 transition-colors flex items-center"><ChevronRight className="w-4 h-4 mr-1" /> Contacto</button></li>
              </ul>
            </div>
            <div>
              <h5 className="text-xl font-bold mb-4">Contáctanos</h5>
              <p className="mb-2">academia.artesimonbolivar@gmail.com</p>
              <p className='mb-2'>+1 829 392 5368</p>
              <p className="mb-2">+1 809 519 1093</p>
              <p>Los Restauradores, calle Manganagua, Edificio Belgica XIX, Republica Dominicana.</p>
            </div>
            <div>
              <h5 className="text-xl font-bold mb-4">Horario de Atención</h5>
              <p className="mb-2">Lunes a Viernes: 9:00 AM - 6:00 PM</p>
              <p>Sábados: 10:00 AM - 2:00 PM</p>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-8 text-center">
            <p>&copy; 2024 Simón Bolívar Arte Academy. Todos los derechos reservados por <a href="https://bucaramarketing.agency" target="_blank" rel="noopener noreferrer" className="text-blue-300 hover:text-blue-100 transition-colors">@Bucaramarketing</a>.</p>
          </div>
        </div>
      </footer>

      {/* Botón flotante de WhatsApp */}
      <motion.button
        className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg z-50"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={handleWhatsAppClick}
        aria-label="Contactar por WhatsApp"
      >
        <MessageCircle size={24} />
      </motion.button>
    </div>
  );
}

export default App;
